import React from "react";
import Layout from "../components/Layout";
import * as styles from '../styles/anketa.module.css';

export default function Anketa() {
  return (
    <Layout>
      <div className={styles.anketa}>
        <div className={styles.anketaText}>
          <h1>Online anketa</h1>
          <p>Poštovani,</p>{" "}
          <p>
            ako Vam je potrebna stručna pomoć nutricioniste a niste u mogućnosti
            da dođete lično i dobijete plan ishrane, onda možete popuniti
            on-line anketu/nutricionistički karton i dobiti Vaš program ishrane
            ovim putem. Anketu/nutricionistički karton možete preuzeti klikom na
            dugme ispod i sačuvati u svom računaru.
          </p>
          <div className={styles.btnWrap}>
            <a href="/anketa.docx" download="" className={styles.downloadBtn}>
              Preuzmi anketu
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const Head = () => <title>Sonja Nikacevic | Nutricionista</title>;
